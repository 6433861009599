export const displayNameMapping: { [key: string]: string } = {
    '': 'Sign In',
    'forgot-password': 'Forgot Password',
    'reset-password': 'Reset Password',
    'dashboard': 'Dashboard',
    'consumer': 'Consumer',
    'consumer-save': 'Consumer Save',
    'Add ': 'Add Appointments',
    'patient-save': 'Add Appointments Save',
    'appointments': 'Reception Appointments',
    'opd-clinical': 'OPD',
    'certificates': 'Certificates',
    'certificates-save': 'Certificates Save',
    'patient-master': 'Patients',
    'patient-master-save': 'Patient Save',
    'patient-history': 'Patient History',
    'staff': 'Staff',
    'staff-save': 'Staff Save',
    'users': 'Users',
    'users-save': 'Users Save',
    'lead': 'Lead',
    'lead-save': 'Lead Save',
    'distribute': 'Distribute',
    'distribute-save': 'Distribute Save',
    'opd-billing': 'Opd Billing',
    'opd-billing-save': 'Opd Billing Save',
    'master': 'Master',
    'medicine-template': 'Medicine Template',
    'medicine-template-save': 'Medicine Template Save',
    'medicine-master': 'Medicine Master',
    'medicine-master-save': 'Medicine Master Save',
    'organization': 'Organization',
    'organization-save': 'Organization Save',
    'branch': 'Branch',
    'branch-save': 'Branch Save',
    'department': 'Department',
    'department-save': 'Department Save',
    'speciality': 'Speciality',
    'speciality-save': 'Speciality Save',
    'settings': 'Settings',
    'doctor-save': 'Doctor Save',
    'doctor': 'Doctor',
    'medical-representative': 'Medical Representative',
    'medical-representative-save': 'Medical Representative Save',
    'diagnosis-type': 'Diagnosis Type',
    'diagnosis-type-save': 'Diagnosis Type Save',
    'icd-codes': 'ICD Codes',
    'icd-codes-save': 'ICD Codes Save',
    'diagnosis-master': 'Diagnosis Master',
    'diagnosis-master-save': 'Diagnosis Master Save',
    'investigation-master': 'Investigation Master',
    'investigation-master-save': 'Investigation Master Save',
    'followup-master': 'Follow Up Master',
    'followup-master-save': 'Follow Up Master Save',
    'instruction-master': 'Instruction Master',
    'instruction-master-save': 'Instruction Master Save',
    'followup-duration': 'Follow Up Duration',
    'followup-duration-save': 'Follow Up Duration Save',
    'followup-reason': 'Follow Up Reason',
    'followup-reason-save': 'Follow Up Reason Save',
    'followup-instruction': 'Follow Up Instruction',
    'followup-instruction-save': 'Follow Up Instruction Save',
    'diagnosis-template': 'Diagnosis Template',
    'diagnosis-template-save': 'Diagnosis Template Save',
    'investigation-template': 'Investigation Template',
    'investigation-template-save': 'Investigation Template Save',
    'certificates-template-save': 'Certificates Template Save',
    'certificates-template': 'Certificates Template',
    'procedure-template-save': 'Procedure/Operation',
    'procedure-template': 'Procedure Template',
    'diet-and-nutrition-instruction': 'Diet and Nutrition Instruction',
    'diet-and-nutrition-instruction-save': 'Diet and Nutrition Instruction Save',
    'investigation-instruction': 'Investigation Instruction',
    'investigation-instruction-save': 'Investigation Instruction Save',
    'medicine-instruction': 'Medicine Instruction',
    'medicine-instruction-save': 'Medicine Instruction Save',
    'special-instruction': 'Special Instruction',
    'special-instruction-save': 'Special Instruction Save',
    'alert-instruction': 'Alert Instruction',
    'alert-instruction-save': 'Alert Instruction Save',
    'general-instruction': 'General Instruction',
    'general-instruction-save': 'General Instruction Save',
    'laboratory': 'Laboratory',
    'laboratory-save': 'Laboratory Save',
    'hospital': 'Hospital',
    'hospital-save': 'Hospital Save',
    'add-appointment': 'Appointments',
    'symptoms': 'Symptoms',
    'symptoms-save': 'Symptoms Save',
    'profile': 'Profile',
    'doctor-schedule': 'Doctor Schedule',
    'outreach-visits': 'Outreach Visits',
    'report': 'Report',
    'billing-item-master': 'Billing',
    'billing-item-master-save': 'Billing  Save',
    'Tax': 'Tax',
    'TaxSave': 'Tax Save',
    'medicine-Generic-name': 'Medicine Generic Name',
    'medicine-Generic-name-save': 'Medicine Generic Name Save',
    'medicine-group': 'Medicine Group',
    'medicine-group-save': 'Medicine Group Save',
    'medicine-company': 'Medicine Company',
    'medicine-company-save': 'Medicine Company Save',
    'medicine-formulation': 'Medicine Formulation',
    'medicine-formulation-save': 'Medicine Formulation Save',
    'medicine-used-as': 'Medicine Used As',
    'medicine-used-as-save': 'Medicine Used As Save',
    'medicine-diluent-name': 'Medicine Diluent Name',
    'medicine-diluent-name-save': 'Medicine Used As Save',


};
