// IMPORT REACT
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// IMPORT PACKAGES
import * as Yup from "yup";
import { format, isValid } from "date-fns";
import dayjs from "dayjs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// IMPORT CSS
import "../../../theme/style.scss";
import "react-phone-number-input/style.css";

// IMPORT UI COMPONENTS
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneInput from "react-phone-number-input";
import { EncryptDecryptService } from "../../../services/common/EncryptionService";
import { GetCountryRegionListForSelect } from "../../../services/CountryRegionService";
import { GetCountryListForSelect } from "../../../services/CountryService";
import SkeletonWrapper from "../../../components/Skeleton/SkeletonWrapper";
import { CustomTextField } from "../../../components/CustomFields/TextField";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import DatePickerComponent from "../../../components/CustomFields/DatePicker";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PreferencesContext } from "../../../PreferenceContext";
import { AlertType } from "../../../services/models/AlertType";
import { CreateUser, GetUserDetail, UpdateUser } from "../../../services/UserService";
import { CheckIsExpireInvitation, SetPassword } from "../../../services/AuthService";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// IMPORT SERVICE


// SCHEMA FOR OPTION
interface selectOption {
  label: string;
  value: string;
}

// SCHEMA FOR FORM
interface formInitialValueTypes {
  userId: number;
  aspNetUserID: string,
  code: string;
  firstName: string;
  middleName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  countryId: selectOption | null;
  countryRegionId: selectOption | null;
  email: string;
  primaryMobileNumber: string;
  isDeleted: boolean;
  newPassword: string;
  confirmPassword: string;
  token: string;
  showPassword: boolean;
  showConfPassword: boolean;
}
// // DEFAULT SCHEMA VALUE FOR FORM
// const initUserData: formInitialValueTypes = {
//   userId: 0,
//   aspNetUserID: "",
//   code: "",
//   firstName: "",
//   middleName: "",
//   lastName: "",
//   address1: "",
//   address2: "",
//   city: "",
//   postalCode: "",
//   countryId: { value: "91", label: "INDIA" },
//   countryRegionId: null,
//   primaryMobileNumber: "",
//   email: userContext.loginDetail.email,
//   isDeleted: false,
//   newPassword: "",
//   confirmPassword: "",
//   token: "",
//   showPassword: false,
//   showConfPassword: false
// };

export default function ManageUser() {
  // HOOKS AND CONTEXT
  const navigate = useNavigate();
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const edService = new EncryptDecryptService();
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }// DEFAULT SCHEMA VALUE FOR FORM
  const initUserData: formInitialValueTypes = {
    userId: 0,
    aspNetUserID: "",
    code: "",
    firstName: "",
    middleName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    countryId: { value: "91", label: "INDIA" },
    countryRegionId: null,
    primaryMobileNumber: "",
    email: userContext.loginDetail.email,
    isDeleted: false,
    newPassword: "",
    confirmPassword: "",
    token: "",
    showPassword: false,
    showConfPassword: false
  };

  // GETTTING PARAMS
  let location = useLocation();
  const query = new URLSearchParams(location.search);
  const localToken = query.get("t") || "";
  // IF NOT FIND THEN NAVIGATE TO NOT FOUND PAGE  --- PENDING -- ASK TO SIR ABOUT THIS



  // STATES FOR DATA STORAGE  -- PLEASE USE PROPER TYPES FOR STATE
  const [countryRegionData, setCountryRegionData] = useState<any[]>([]); // REPLACE ANY WITH COUNTRY INTERFACE
  const [countryData, setCountryData] = useState<any[]>([]); // REPLACE ANY WITH STATE INTERFACE

  // STATES -- FOR ERROR HANDLING AND LOADING
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  //--show & hide function for NewPassword--
  const handleClickShowPassword = () => {
    setUserInitial({
      ...userinitial,
      showPassword: !userinitial.showPassword,
    });
  };

  //--show & hide function for CondormPassword--
  const handleClickShowConfPassword = () => {
    setUserInitial({
      ...userinitial,
      showConfPassword: !userinitial.showConfPassword,
    });
  };
  //SCHEMA VALIDATION
  const [userinitial, setUserInitial] = React.useState(initUserData);
  const validationSchema = Yup.object().shape({
    userId: Yup.number().nullable(),
    code: Yup.string(),
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    address1: Yup.string().required("Address 1 is Required"),
    address2: Yup.string(),
    city: Yup.string().required("City is Required"),
    postalCode: Yup.string().required("Postal Code is Required"),
    countryId: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .required("Country is Required"),
    countryRegionId: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .required("State is Required"),
    primaryMobileNumber: Yup.string().required(
      "Primary Mobile Number is Required"
    ),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    newPassword: Yup.string()
      .required("New Password is required.")
      .matches(
        /^(?=.{8,})(?=.*[a-z])((?=.*[\d+]))(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/,
        "Password should contain at least 8 characters, including one capital, one small, one special letter and one number"
      )
    ,
    confirmPassword: Yup.string()
      .required("Confirm Password is required.")
      .oneOf([Yup.ref("newPassword"), null], "Confirm password doesn't match with New Password"),
    //isDeleted: Yup.boolean(),
  });
  // FORM HOOK
  const methods = useForm({
    resolver: yupResolver(validationSchema), // IGNORE THIS ERROR
    defaultValues: userinitial, // DEFAULT VALUE -- DON'T CREATE STATE FOR IT -- STATE CAN INCREASE SITE LOAD
  });
  //Init
  async function init() {
    const formData = {
      Token: localToken,
    }
    try {
      const result: any = await CheckIsExpireInvitation(formData);
      if (result.data) {
        setUserInitial({ ...userinitial, token: localToken, email: result?.data?.data?.email });
        updateUserContext({
          ...userContext, isMenuVisible: true,
          loginDetail: { ...userContext.loginDetail, token: result?.data?.token, userId: 0, userKey: result?.data?.userKey, email: result?.data?.data?.email }
        });
        console.table(userinitial)
        if (result.data?.isExpired) {
          //etShowData(false)
          console.log("the user is expired");
        }
        else if (result.data?.isRegister) {
          //setIsRegister(true);
        } else if (result.data?.isPartial) {
          //setIsPartial(true)
        }
      }
    } catch (error) {
    }
  }
  // GET CONSUMER DETAIL
  // const getUserDetailById = async () => {
  //   let user: any;
  //   let params = new URLSearchParams();
  //   params.append("UserId", curUserId.toString());
  //   params.append("ConsumerId", userContext.consumerId.toString());
  //   params.append("organizationId", userContext.organizationId.toString() || '1');
  //   params.append("BranchId", userContext.branchId.toString());
  //   params.append("DepartmentId", userContext.departmentId.toString());
  //   params.append("SpecialityId", '1');
  //   try {
  //     const res: any = await GetUserDetail(params);
  //     user = res.data.data;
  //     methods.reset({
  //       userId: user?.userId || 0,
  //       aspNetUserID: user?.aspNetUserID,
  //       code: user?.code || "",
  //       firstName: user?.firstName || "",
  //       middleName: user?.middleName || "",
  //       lastName: user?.lastName || "",
  //       address1: user?.address1 || "",
  //       address2: user?.address2 || "",
  //       city: user?.city || "",
  //       postalCode: user?.postalCode || "",
  //       countryId: user?.countryId
  //         ? {
  //           label: user?.countryLabel,
  //           value: user?.countryId,
  //         }
  //         : null,
  //       countryRegionId: user?.countryRegionId
  //         ? {
  //           label: user?.countryRegionLabel,
  //           value: user?.countryRegionId,
  //         }
  //         : null,
  //       email: user?.email || "",
  //       primaryMobileNumber: user?.primaryMobileNumber || ""
  //     });
  //     return user;
  //   } catch (e) {
  //     updateUserContext({
  //       ...userContext,
  //       isAlert: true,
  //       alertMessage:
  //         typeof e === "string" ? (e as string) : (e?.message as string),
  //       alertType: AlertType.Error,
  //     });
  //   }
  // };

  // SAVE AND UPDATE CONSUMER
  const saveUser = async (value: formInitialValueTypes) => {
    
    try {
      let result: any = "";
      let newPassword = value?.newPassword;
      let confirmPassword = value.confirmPassword;

      const query = new URLSearchParams(location.search);
      const token = query.get("t") || "";
      let formData = {
        userId: value.userId,
        aspNetUserID: value.aspNetUserID,
        consumerId: userContext.consumerId.toString(),
        orgId: userContext.organizationId.toString(),
        branchId: userContext.branchId.toString(),
        departmentId: userContext.departmentId.toString(),
        specialityId: '1',
        code: value.code?.trim(),
        firstName: value.firstName?.trim(),
        middleName: value.middleName?.trim(),
        lastName: value.lastName?.trim(),
        address1: value.address1?.trim(),
        address2: value.address2?.trim(),
        city: value.city?.trim(),
        postalCode: value.postalCode?.trim(),
        countryRegionId: value.countryRegionId?.value,
        countryId: value.countryId?.value,
        primaryMobileNumber: value.primaryMobileNumber?.trim(),
        email: userContext.loginDetail.email,
        Password: newPassword,
        ConfirmPassword: confirmPassword,
        UserName: value.email?.trim(),
        DataManagedBy: userContext.loginDetail.userId,
        role: userContext.loginDetail.userRole,
        token: token,
      };
      if (userContext.loginDetail.userId > 0) {
        navigate("/");
      } else {
        result = await SetPassword(formData);
      }
      if (result?.data) {
        navigate("/");
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result?.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: "Something went wrong.",
          alertType: AlertType.Error,
        });
      }
    } catch (e) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  };

  // GET COUNTRIES
  async function fetchCountry() {
    try {
      let params = new URLSearchParams();
      const countryData: any = await GetCountryListForSelect(params);
      if (countryData?.data?.data) {
        let countyCollection = countryData.data.data;
        setCountryData(countyCollection);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  // FETCH STATE FOR COUNTRY
  async function fetchCountryRegionData(countryId) {
    try {
      let params = new URLSearchParams();
      params.append("countryId", countryId);
      const localCountryRegionData: any = await GetCountryRegionListForSelect(
        params
      );
      if (localCountryRegionData?.data?.data) {
        let stateCollection = localCountryRegionData.data.data;
        setCountryRegionData(stateCollection);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  // ON SUBMIT OF FORM
  const onSubmit = async (data: any) => {
    saveUser(data);
  };

  // HANDLE BACK
  const handleBack = () => {
    navigate(-1);
  };

  // FETCH DATA
  // const fetchData = async () => {
  //   try {
  //     await fetchCountry();
  //     if (curUserId > 0) {
  //       const user = await getUserDetailById();
  //       await fetchCountryRegionData(user?.countryId);
  //     } else {
  //       await fetchCountryRegionData(methods.getValues()?.countryId?.value);
  //     }
  //     setIsLoading(false);
  //   } catch (e) {
  //     setIsLoading(false);
  //     console.log(e);
  //   }
  // };

  // USEEFFECTS
  useEffect(() => {
    setIsLoading(true);
    fetchCountry();
    fetchCountryRegionData(91)
  }, []);

  useEffect(() => {
    init();
  }, []
  )

  // HANDLE DELAY FOR SKELETON
  useEffect(() => {
    if (!isLoading) {
      const timeout = setTimeout(() => setShowSkeleton(false), 200); // Delay unmounting the Skeleton
      return () => clearTimeout(timeout);
    } else {
      //setShowSkeleton(true);
    }
  }, [isLoading]);

  return (
    <Box>
      <Box className={"main-container"}>
        <Grid container className="page-header">
          <Grid item xs={6} sm={6} md={6}>
            <Box sx={{ display: "flex", height: "100%", alignItems: "center" }}>
              <CustomBreadcrumbs />
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Box className="page-header-icon">
              <Button
                color="primary"
                variant="outlined"
                type="button"
                className="secondary-button"
                startIcon={<ArrowBackIcon />}
                onClick={handleBack}
              >
                Back
              </Button>
            </Box>
          </Grid>
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} action="">
            <Grid container gap={2}>
              <Grid item xs={12} className={"group-container"}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Account Information</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormLabel sx={{ display: 'flex', justifyContent: 'left', fontSize: '13px' }} className={`fileds-label-text  ${methods.formState.errors.newPassword ? 'validation-message' : ''}`}>Password</FormLabel>
                    <CustomTextField
                      id="newPassword"
                      //label="New Password*"
                      fullWidth
                      size="small"
                      margin="dense"
                      // value={props.values.password}
                      // onChange={props.handleChange}
                      // onBlur={props.handleBlur}
                      {...methods.register('newPassword')}
                      error={
                        !!methods.formState.errors.newPassword
                      }
                      className={`text-input ${methods.formState.errors.newPassword ? 'error' : ''}`}
                      inputProps={{ enterKeyHint: 'next' }}
                      type={userinitial.showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {userinitial.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="inherit" className="validation-message" sx={{ textAlign: 'left' }}>
                      {methods.formState.errors.newPassword?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8} />
                  <Grid item xs={12} sm={6} md={4}>
                    <FormLabel sx={{ display: 'flex', justifyContent: 'left', fontSize: '13px' }} className={`fileds-label-text  ${methods.formState.errors.confirmPassword ? 'validation-message' : ''}`}>Password</FormLabel>
                    <CustomTextField
                      id="confirmPassword"
                      //label="Confirm Password*"
                      fullWidth
                      size="small"
                      margin="dense"
                      // value={props.values.password}
                      // onChange={props.handleChange}
                      // onBlur={props.handleBlur}
                      {...methods.register('confirmPassword')}
                      error={
                        !!methods.formState.errors.confirmPassword
                      }
                      className={`text-input ${methods.formState.errors.confirmPassword ? 'error' : ''}`}
                      inputProps={{ enterKeyHint: 'next' }}
                      type={userinitial.showConfPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle Confpassword visibility"
                              onClick={handleClickShowConfPassword}
                              edge="end"
                            >
                              {userinitial.showConfPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="inherit" className="validation-message" sx={{ textAlign: 'left' }}>
                      {methods.formState.errors.confirmPassword?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8} />
                </Grid>
              </Grid>
              <Grid item xs={12} className={"group-container"}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Basic Information</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.code
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        Code
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <CustomTextField
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register("code")}
                          error={!!methods.formState.errors.code}
                          className={`text-input ${methods.formState.errors.code ? "error" : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {methods.formState.errors.code?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} />

                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.firstName
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        First Name *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <CustomTextField
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register("firstName", {
                            required: true,
                            onChange: (e) => {
                              // CONVERT INPUT INTO UPPERCASE LATTERS
                              e.target.value = e.target.value
                                ? e.target.value.toUpperCase()
                                : "";
                              methods.setValue("firstName", e.target.value, {
                                shouldValidate: true,
                              }); // UPDATE VALUE
                            },
                          })}
                          error={!!methods.formState.errors.firstName}
                          className={`text-input ${methods.formState.errors.firstName ? "error" : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {methods.formState.errors.firstName?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.middleName
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        Middle Name
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <CustomTextField
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register("middleName", {
                            required: true,
                            onChange: (e) => {
                              // CONVERT INPUT INTO UPPERCASE LATTERS
                              e.target.value = e.target.value
                                ? e.target.value.toUpperCase()
                                : "";
                              methods.setValue("middleName", e.target.value, {
                                shouldValidate: true,
                              }); // UPDATE VALUE
                            },
                          })}
                          error={!!methods.formState.errors.middleName}
                          className={`text-input ${methods.formState.errors.middleName ? "error" : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {methods.formState.errors.middleName?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.lastName
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        Last Name *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <CustomTextField
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register("lastName", {
                            required: true,
                            onChange: (e) => {
                              // CONVERT INPUT INTO UPPERCASE LATTERS
                              e.target.value = e.target.value
                                ? e.target.value.toUpperCase()
                                : "";
                              methods.setValue("lastName", e.target.value, {
                                shouldValidate: true,
                              }); // UPDATE VALUE
                            },
                          })}
                          error={!!methods.formState.errors.lastName}
                          className={`text-input ${methods.formState.errors.lastName ? "error" : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {methods.formState.errors.lastName?.message}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={6} /> */}
                </Grid>
              </Grid>
              <Grid item xs={12} className={"group-container"}>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Contact Information</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.address1
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        Address 1 *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <CustomTextField
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register("address1", { required: true })}
                          error={!!methods.formState.errors.address1}
                          className={`text-input ${methods.formState.errors.address1 ? "error" : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {methods.formState.errors.address1?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.address2
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        Address 2
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <CustomTextField
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register("address2")}
                          error={!!methods.formState.errors.address2}
                          className={`text-input ${methods.formState.errors.address2 ? "error" : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {methods.formState.errors.address2?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.city
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        City *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <CustomTextField
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register("city", { required: true })}
                          error={!!methods.formState.errors.city}
                          className={`text-input ${methods.formState.errors.city ? "error" : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {methods.formState.errors.city?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.postalCode
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        Postal Code *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <CustomTextField
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          id="postalCode"
                          size="small"
                          {...methods.register("postalCode", {
                            required: true,
                          })}
                          error={!!methods.formState.errors.postalCode}
                          className={`text-input ${methods.formState.errors.postalCode ? "error" : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {methods.formState.errors.postalCode?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.countryId
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        Country *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <Controller
                          control={methods.control}
                          name="countryId"
                          render={({ field }) => (
                            <CustomSelect
                              {...field}
                              isNotCreatable={true}
                              id={"countryId"}
                              FieldName={"countryId"}
                              options={countryData}
                              openMenuOnFocus
                              isClearable
                              isSearchable
                              getOptionValue={(option: any) => option.value}
                              getOptionLabel={(option: any) => option.label}
                              menuPortalTarget={document.querySelector("body")}
                              handleChange={async (e) => {
                                if (
                                  methods
                                    ?.getValues()
                                    ?.countryId?.value?.toString() !==
                                  e?.value?.toString()
                                ) {
                                  methods.setValue(
                                    "countryId",
                                    e?.value ? e : null,
                                    {
                                      shouldValidate: true,
                                    }
                                  );
                                  methods.setValue("countryRegionId", null, {
                                    shouldValidate: true,
                                  });
                                  if (e?.value !== null) {
                                    setIsLoading(true);
                                    await fetchCountryRegionData(e?.value);
                                    setIsLoading(false);
                                  } else {
                                    setCountryRegionData([]);
                                  }
                                }
                              }}
                              className={`text-input ${methods.formState.errors.postalCode
                                ? "error"
                                : ""
                                }`}
                            />
                          )}
                        />
                      </SkeletonWrapper>
                      <Typography className="validation-message">
                        {methods.formState.errors.countryId?.message}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.countryRegionId
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        State *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <Controller
                          control={methods.control}
                          name="countryRegionId"
                          render={({ field }) => (
                            <CustomSelect
                              {...field}
                              isNotCreatable={true}
                              id={"countryRegionId"}
                              FieldName={"countryRegionId"}
                              options={countryRegionData}
                              openMenuOnFocus
                              isClearable
                              isSearchable
                              getOptionValue={(option: any) => option.value}
                              getOptionLabel={(option: any) => option.label}
                              menuPortalTarget={document.querySelector("body")}
                              handleChange={(e) => {
                                methods.setValue(
                                  "countryRegionId",
                                  e?.value ? e : null,
                                  {
                                    shouldValidate: true,
                                  }
                                );
                              }}
                              className={`text-input ${methods.formState.errors.countryId
                                ? "error"
                                : ""
                                }`}
                            />
                          )}
                        />
                      </SkeletonWrapper>
                      <Typography className="validation-message">
                        {methods.formState.errors.countryRegionId?.message}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.email
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        Email *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <CustomTextField
                          disabled
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register("email", { required: true })}
                          error={!!methods.formState.errors.email}
                          className={`text-input ${methods.formState.errors.email ? "error" : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {methods.formState.errors.email?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="input-group">
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors.primaryMobileNumber
                          ? "validation-message"
                          : ""
                          }`}
                      >
                        Primary Mobile Number *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input"
                      >
                        <PhoneInput
                          id="primaryMobileNumber"
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="IN"
                          {...methods.register("primaryMobileNumber", {
                            required: true,
                            shouldUnregister: true,
                          })}
                          error={
                            methods.formState.errors.primaryMobileNumber
                              ?.message
                          }
                          className={`phone-input ${methods.formState.errors.primaryMobileNumber
                            ? "error"
                            : ""
                            }`}
                          onChange={(e) => {
                            methods.setValue("primaryMobileNumber", e || "");
                          }}
                          value={methods.getValues()?.primaryMobileNumber}
                          placeholder="Enter phoneś number"
                        />
                      </SkeletonWrapper>
                      <Typography className="validation-message">
                        {methods.formState.errors.primaryMobileNumber?.message}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                  <LoadingButton
                    color="primary"
                    loadingPosition="start"
                    startIcon={<></>}
                    variant="contained"
                    type="submit"
                    className="primary-button"
                    name="save"
                    disabled={showSkeleton}
                  >
                    Save
                  </LoadingButton>
                  <LoadingButton
                    color="primary"
                    loadingPosition="start"
                    startIcon={<></>}
                    variant="outlined"
                    type="button"
                    className="secondary-button"
                    name="cancel"
                    onClick={handleBack}
                  >
                    Cancel
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
}
