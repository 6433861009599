// IMPORT REACT
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// IMPORT CSS
import "./App.css";
import "./theme/style.scss";

// IMPORT UI COMPONENTS
import Alerts from "./components/Common/Alerts";
import UnauthorizeDialog from "./components/UnauthorizeDialog/UnauthorizeDialog";
import { ThemeProvider } from "@mui/material/styles";

// IMPORT SERVICES
import { lightTheme } from "./theme/Palette/light-palette";
import { Context } from "./services/models/Context";
import { getStorage, getVersion, setStorage } from "./services/common/storage";
import { PreferencesContext } from "./PreferenceContext";
import { GetAndroidConfig } from "./services/ConfigService";
import {
  getNotificationStorage,
  setNotificationStorage,
} from "./services/common/NotificationStorage";
import { ANDROID_APP_VERSION, IOS_APP_VERSION } from "./services/common/const";

//import { SendNotification } from './services/UserService';
//import { useTranslation } from 'react-i18next';

function App() {
  // HOOKS AND CONTEXT
  const navigate = useNavigate();
  let location = useLocation();
  const [userContext, setUserContext] = useState<Context>(getStorage());

  // STATES FOR DATA STORAGE OR OTHER PURPOSE
  const [isAndroidRestrict, setAndroidRestrict] = useState(false);
  const [isIPhoneRestrict, setIPhoneRestrict] = useState(false);
  const [isWebRestrict, setWebRestrict] = useState(false);
  const [mode, setMode] = useState<"light" | "dark" | "">(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "light"
      : "light"
  ); // FOR FUTURE PURPOSE

  // STATES ERROR HANDLING
  const [isLoading, setLoading] = useState(true);

  //const { i18n, t } = useTranslation();

  const updateUserContext = React.useCallback(
    (context: any) => {
      setStorage(context);
      return setUserContext(context);
    },
    [userContext]
  );

  const preferences = React.useMemo(
    () => ({
      updateUserContext,
      userContext,
    }),
    [userContext]
  );

  const setLanguage = () => {
    // if (userContext.memberDetail !== undefined && userContext.memberDetail !== null) {
    //   if (userContext.memberDetail.curMemberSelectedLanguage !== "") {
    //     i18n.changeLanguage(userContext.memberDetail.curMemberSelectedLanguage);
    //   } else {
    //     i18n.changeLanguage('en');
    //   }
    // } else {
    //   i18n.changeLanguage('en');
    // }
  };

  let publicPages = ['/', 'sign-up', 'thank-you', 'reset-password', 'set-password', 'forgot-password', "terms", "privacy-policy", "android"];

  // CHECK IF USER IS ALREADY HAVE SESSION 
  async function checkIfLoggedIn() {

    let version = getVersion();
    let androidVersion = ANDROID_APP_VERSION;  
    let iosVersion = IOS_APP_VERSION; 
    var context = new Context();
    let page = window.location.href.split(window.location.host)[1];

    let allPages = ['reset-password', 'set-password', 'forgot-password', 'sign-up', 'thank-you', 
      'consumers', 'consumer-save', 
      'settings', 'organization', 'organization-save','branch', 'branch-save',
      'department', 'department-save','speciality', 'speciality-save', 'language',
      'dashboard', 'add-appointment', 'appointments',
      'opd-clinical','certificates', 'patient-master', 'patient-history',
      'staff','users','lead','distribute','opd-billing',
      'master'

      // 'suppliers', 'products', 'taxes', 'units'
      // , 'purchase', 'invoice', 'delivery-challan', 'invoicereturn', 'stock', 'stock-adjustment', 'stock-adjustment-save', 'user', 'staff', 'product-category', 'branches', 'firms', 'buyers', 'gst-report', 'my-profile', 'product-category-save'
      // , 'product-save', 'unit-save', 'purchase-save', 'PurchaseSave', 'invoice-Setting', 'invoice-save', 'SaleSaveReturn', 'SaleSave', 'buyer-save', 'supplier-save', 'user-save',
      // , 'consumer-save', 'staff-save', 'tax-save', 'branch-save', 'firm-save', 'help-center', 'delivery-challan-save', 'sales-register', 'balance-sheet', 'ledger-group-summary', 'ledger-monthly-summary', 'ledger-voucher', 'day-book', 'daily-purchases-report', 'daily-sales-report', 'staff-summary-report', 'sales-details-by-saleperson',
      // , 'sales-summery-by-customer','outstanding-receivable-summary','outstanding-payable-summary', 'shipping-address', 'shipping-address-save', 'credit-note', 'credit-note-save', 'debit-note', 'debit-note-save', 'settings', 'report-menu', 'ledger', 'ledger-save', 'purchase-return', 'sale-return',
      // 'transporters', 'transporter-save', "payment-made", "payment-made-save", "payment-received", "payment-received-save", "voucher", "voucher-save", "journal-voucher", "journal-voucher-save"
      // , "warehouses", "warehouse-save", "ledger-groups", "ledger-group-save", "stock-statement", "profit-and-loss", "terms", "privacy-policy", "trial-balance", "purchase-register", "android", "subscription", "e-way-bill", "e-way-bill-update", "e-way-bill-extend"
      // , "price-list", 'gstr1-report', 'pos', 'areawise-report', 'area', 'area-save', 'stock-summary-by-supplier-report', 'stock-summary-by-buyer-report', 'stock-detail-by-buyer-report', 'stock-summary-by-product-buyer-report', 'free-good-summary-by-category-report',
      // 'free-good-detail-by-buyer-report', 'free-good-summary-by-buyer-report', 'stock-by-category', 'stock-by-product', 'stock-reports', 'quotation-detail-report', 'purchase-detail-report',
      // 'gstr2-report', 'gstr3B-report', 'user-group', 'user-group-save', 'outstanding-payable', 'outstanding-receivable','outstanding-receivable-new', 'account-and-stock-transfer', 'config-barcode', 'from-to-address', 'from-to-address-save', 'sale-by-customer', 'sales-summery-by-Product','sale-by-product','purchase-summary-by-supplier','purchase-details-by-supplier',
      // 'purchase-summary-by-Product','purchase-details-by-product','stock-by-warehouse','cash-receive-party-wise','outstanding-payable-new', 'user-group', 'user-group-save','user-group-access'
    ];

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const urlRemoveLocalStorage = params.get("rm");
    if (urlRemoveLocalStorage === "1") {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      updateUserContext(context);
      setLoading(false);
      navigate("/");
    }

    const urlFroceUpdate = params.get("cc");
    if (urlFroceUpdate === "1") {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }

    const result: any = await GetAndroidConfig();
    if (
      result !== undefined &&
      result !== null &&
      result.data !== null &&
      result.data.data !== null
    ) {
      let data = result.data.data;

      // for android
      if (androidVersion !== data.androidAppVersion) {
        if (data.androidForceLogout === true) {
          updateUserContext(context);
          navigate("/");
        } else {
          //cookies
        }
      }

      // for ios
      if (iosVersion !== data.iosAppVersion) {
        if (data.iosForceLogout === true) {
          updateUserContext(context);
          navigate("/");
        } else {
          //cookies
        }
      }

      // for version
      if (version !== data.version) {
        if (data.versionForceLogout === true) {
          updateUserContext(context);
          navigate("/");
        } else {
          //cookies
        }
      }
    }
    setLoading(false);
    if (
      userContext &&
      userContext.loginDetail &&
      userContext.loginDetail.token &&
      userContext.loginDetail.token.length > 0
      
    ) {
      // if (userContext?.loginDetail?.isAuthorized === false) {
      //   updateUserContext(context);
      //   navigate("/");
      // } else {
      //   const windowUrl = window.location.pathname.length;
      //   if (windowUrl < 2) {
      //     navigate("/dashboard");
      //   }
      // }
      if (userContext.loginDetail.userId > 0) {
        if (userContext.consumerId > 0) {
          if (page && page !== null && page !== undefined) {
            let curPage = page.split('/')[1];

            //for Query params
            let isParams = curPage.includes("?");
            if (isParams === true) {
              curPage = curPage.split('?')[0];
            }

            if (curPage !== "") {
              let isPage = allPages.findIndex((x) => x === curPage);
              if (isPage >= 0) {
                // navigate(page);
              } else {
                navigate("/not-found");
              }
            } else {
              navigate("/dashboard");
            }
          } else {
            // history.push("/dashboard");
            navigate("/not-found");
          }
        }
        else {
          if (page && page != null && page !== undefined) {
            let curPage = page.split('/')[1];

            //for Query params
            let isParams = curPage.includes("?");
            if (isParams === true) {
              curPage = curPage.split('?')[0];
            }

            if (curPage !== "") {
              let isPage = allPages.findIndex((x) => x === curPage);
              if (isPage >= 0) {
              } else {
                navigate("/not-found");
              }
            } else {
              navigate("/consumers");
            }
          } else {
            navigate("/");
          }

        }
      }
      else {

        if (page && page != null && page !== undefined) {
          let curPage = page.split('/')[1];

          //for Query params
          let isParams = curPage.includes("?");
          if (isParams === true) {
            curPage = curPage.split('?')[0];
          }

          if (curPage !== "") {
            let isPage = publicPages.findIndex((x) => x === curPage);
            if (isPage >= 0) {
              // navigate(page);
            } else {
              navigate("/");
            }
          }
          else {
            navigate("/");
          }

        } else {
          navigate("/");
        }
      }
    }else {
      if (page && page != null && page !== undefined) {
        let curPage = page.split('/')[1];

        //for Query params
        let isParams = curPage.includes("?");
        if (isParams === true) {
          curPage = curPage.split('?')[0];
        }

        if (curPage !== "") {
          let isPage = publicPages.findIndex((x) => x === curPage);
          if (isPage >= 0) {
            // navigate(page);
          } else {
            //history.push("/not-found");
            navigate("/");
          }
        }
        else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    }
    //  else {
    //   updateUserContext(context);
    //   const windowUrl = window.location.pathname.length;
    //   if (windowUrl < 2) {
    //     navigate("/");
    //   }
    // }
  }

  // GET DEVICE OPERATING SYSTEM
  function getOS() {
    let os = navigator.userAgent;
    let finalOs = "Unknown";
    if (os.search("Windows") !== -1) {
      finalOs = "Windows";
    } else if (os.search("Mac") !== -1 && os.search("iPhone") !== -1) {
      finalOs = "iPhone";
    } else if (os.search("Mac") !== -1 && os.search("iPad") !== -1) {
      finalOs = "iPad";
    } else if (os.search("Mac") !== -1) {
      finalOs = "Mac";
    } else if (os.search("X11") !== -1 && !(os.search("Linux") !== -1)) {
      finalOs = "UNIX";
    } else if (os.search("Linux") !== -1 && os.search("X11") !== -1) {
      finalOs = "Linux";
    } else if (os.search("Android") !== -1) {
      finalOs = "Android";
    }
    return finalOs;
  }

  // const isInStandaloneMode = () =>
  //   (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

  const isInStandaloneMode = () =>
    window.matchMedia("(display-mode: standalone)").matches ||
    document.referrer.includes("android-app://");

  // GET NOTIFICATION VALUE
  let notificationValue = getNotificationStorage();

  // SET NOTIFICATION TO STORAGE
  async function setNotificationDataToStorage() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const urlToken = params.get("t");
    const urlDevice = params.get("m");
    let notificationData: any = {
      token: urlToken,
      mode: urlDevice,
    };
    setNotificationStorage(notificationData);
    sendNotification();
  }

  // SEND NOTIFICATION
  async function sendNotification() {
    // let notificationStorageData: any = getNotificationStorage();
    // if (notificationStorageData !== undefined && notificationStorageData !== null) {
    //   if (userContext !== undefined && userContext.loginDetail !== undefined && userContext.loginDetail !== null) {
    //     if (userContext.memberDetail !== undefined && userContext.memberDetail !== null) {
    //       if (userContext.loginDetail.familyId > 0 && userContext.memberDetail.familyMemberId > 0
    //         && notificationStorageData.mode != null && notificationStorageData.token != null) {
    //         let notificationData = {
    //           familyId: userContext.loginDetail.familyId,
    //           familyMemberId: userContext.memberDetail.familyMemberId,
    //           device: notificationStorageData.mode,
    //           token: notificationStorageData.token,
    //           dataManagedBy: userContext.loginDetail.userId,
    //         };
    //         const resultParentSave: any = await SendNotification(notificationData);
    //       }
    //     }
    //   }
    // }
  }

  // USE EFFECTS
  useEffect(() => {
    let os = getOS();
    if (isInStandaloneMode()) {
      if (os === "Android") {
        setAndroidRestrict(true);
      }
      if (os === "iPhone") {
        setIPhoneRestrict(true);
      }
      if (os === "Windows") {
        setWebRestrict(true);
      }
    } else {
      if (os === "Android") {
        setAndroidRestrict(true);
      }
      if (os === "iPhone") {
        setIPhoneRestrict(true);
      }
      if (os === "Windows") {
        setWebRestrict(true);
      }
    }
    // setNotificationDataToStorage();
    // setLanguage();
  }, []);

  useEffect(() => {
    checkIfLoggedIn();
  }, [location.pathname]);

  // useEffect(() => {
  //   sendNotification();
  // }, [userContext.memberDetail.familyMemberId]);

  return (
    <ThemeProvider theme={mode === "dark" ? lightTheme : lightTheme}>
      <PreferencesContext.Provider value={preferences}>
        {userContext && (
          <>
            <Alerts
              isOpen={userContext.isAlert}
              displayMessage={userContext.alertMessage}
              type={userContext.alertType}
            />
          </>
        )}
        <UnauthorizeDialog isOpen={!userContext?.loginDetail?.isAuthorized} />
        <Outlet context={[mode]} />
      </PreferencesContext.Provider>
    </ThemeProvider>
  );
}

export default App;
